.orders-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #fff7e1;
}
.orders-container01 {
  width: 925px;
  height: 1002px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff7e1;
}
.orders-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #fff7e1;
}
.orders-text {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 50px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
}
.orders-container03 {
  width: 314px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.orders-container04 {
  gap: 5px;
  flex: 0 0 auto;
  width: 150px;
  height: 60px;
  display: flex;
  align-self: center;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(21, 30, 70);
}
.orders-image {
  width: 40px;
  object-fit: cover;
}
.orders-text01 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  text-align: center;
}
.orders-container05 {
  gap: 5px;
  flex: 0 0 auto;
  width: 150px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(21, 30, 70);
}
.orders-image01 {
  width: 35px;
  object-fit: cover;
}
.orders-text02 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  text-align: center;
}
.orders-container06 {
  width: 798px;
  height: 847px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.orders-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.orders-container08 {
  flex: 0 0 auto;
  width: 150px;
  height: 52px;
  display: flex;
  align-items: center;
  border-color: #40b06d;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #FFF7E1;
  border-top-left-radius: 20PX;
  border-top-right-radius: 20PX;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text03 {
  fill: #40b06d;
  color: rgb(64, 176, 109);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.orders-container09 {
  flex: 0 0 auto;
  width: 150px;
  height: 52px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: rgb(64, 176, 109);
  border-top-left-radius: 20PX;
  border-top-right-radius: 20PX;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text04 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.orders-container10 {
  flex: 0 0 auto;
  width: 150px;
  height: 52px;
  display: flex;
  align-items: center;
  border-color: #40b06d;
  border-style: solid;
  border-width: 3px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #FFF7E1;
  border-top-left-radius: 20PX;
  border-top-right-radius: 20PX;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text05 {
  fill: #40b06d;
  color: rgb(64, 176, 109);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.orders-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: rgb(64, 176, 109);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text06 {
  fill: #fff7e1;
  color: #fff7e1;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-text07 {
  fill: #fff7e1;
  color: #fff7e1;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-text08 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-text09 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-text10 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-text11 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-text12 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.orders-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-container13 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orders-container14 {
  flex: 0 0 auto;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: #F0532F;
}
.orders-text13 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
}
.orders-text14 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
}
.orders-text15 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
}
.orders-text16 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
}
.orders-text17 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-unit);
}
.orders-text18 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.orders-container15 {
  gap: 10px;
  width: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.orders-image02 {
  width: 30px;
  object-fit: cover;
}
.orders-image03 {
  width: 30px;
  object-fit: cover;
}
.orders-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text19 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
}
.orders-text20 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
}
.orders-text21 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
}
.orders-text22 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
}
.orders-text23 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-unit);
}
.orders-text24 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.orders-container17 {
  gap: 10px;
  width: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.orders-image04 {
  width: 30px;
  object-fit: cover;
}
.orders-image05 {
  width: 30px;
  object-fit: cover;
}
.orders-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #99d2ac;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text25 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
}
.orders-text26 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
}
.orders-text27 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
}
.orders-text28 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
}
.orders-text29 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-unit);
}
.orders-text30 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.orders-container19 {
  gap: 10px;
  width: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.orders-image06 {
  width: 30px;
  object-fit: cover;
}
.orders-image07 {
  width: 30px;
  object-fit: cover;
}
.orders-container20 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text31 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
}
.orders-text32 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
}
.orders-text33 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
}
.orders-text34 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
}
.orders-text35 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-unit);
}
.orders-text36 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.orders-container21 {
  gap: 10px;
  width: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.orders-image08 {
  width: 30px;
  object-fit: cover;
}
.orders-image09 {
  width: 30px;
  object-fit: cover;
}
.orders-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text37 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
}
.orders-text38 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
}
.orders-text39 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
}
.orders-text40 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
}
.orders-text41 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-unit);
}
.orders-text42 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.orders-container23 {
  gap: 10px;
  width: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.orders-image10 {
  width: 30px;
  object-fit: cover;
}
.orders-image11 {
  width: 30px;
  object-fit: cover;
}
.orders-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.orders-text43 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
}
.orders-text44 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
}
.orders-text45 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-threeunits);
}
.orders-text46 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-left: 0px;
  margin-right: 0px;
}
.orders-text47 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-unit);
}
.orders-text48 {
  fill: rgb(21, 30, 70);
  color: rgb(21, 30, 70);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
}
.orders-container25 {
  gap: 10px;
  width: 74px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.orders-image12 {
  width: 30px;
  object-fit: cover;
}
.orders-image13 {
  width: 30px;
  object-fit: cover;
}
.orders-container26 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: rgb(64, 176, 109);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.orders-text49 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.orders-text50 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.orders-container27 {
  gap: 30px;
  flex: 0 0 auto;
  width: 50%;
  height: 60px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  background-color: rgb(21, 30, 70);
}
.orders-text51 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
}
.orders-text52 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
}
.orders-container28 {
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.orders-text53 {
  fill: #99d2ac;
  color: rgb(153, 210, 172);
  font-size: 22px;
  align-self: center;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}
.orders-container29 {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: #99d2ac;
}
.orders-text54 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
}
.orders-text55 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
}
.orders-text56 {
  fill: #fff7e1;
  color: rgb(255, 247, 225);
  font-size: 20px;
  align-self: center;
  font-style: normal;
  font-weight: 300;
}
@media(max-width: 1600px) {
  .orders-container {
    justify-content: flex-start;
  }
  .orders-container01 {
    width: 100%;
  }
  .orders-container02 {
    width: 80%;
  }
  .orders-container06 {
    width: 65%;
  }
}
