.menu-item-container {
    display: flex;
    align-items: stretch; /* Ensure items stretch to match the height */
    margin: 1rem 0;
}

.menu-item-image {
    width: 40%;
    flex-shrink: 0;
    align-self: stretch; /* Stretch to match height */
}

.menu-item-info {
    flex: 1;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-self: stretch; /* Stretch to match height */
}

.menu-item-info {
    flex: 1;
    margin-left: 2rem;
    display: flex; /* Ensure flex properties are applied */
    flex-direction: column; /* Stack items vertically on smaller screens */
}

/* Responsive adjustments */
@media (max-width: 1600px) {
    .menu-item-container {
        flex-direction: column;
        align-items: center;
    }

    .menu-item-image,
    .menu-item-info {
        width: 100%;
    }

    .menu-item-image {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .menu-item-info {
        margin-left: 0;
    }
}
